<!--
 * @Descripttion: 咨询中心
 * @version: V1.0.0
 * @Author: Shuangshuang Song
 * @Date: 2020-07-03 15:54:54
 * @LastEditors: Shuangshuang Song
 * @LastEditTime: 2020-07-03 17:33:58
-->
<template>
  <div class="reference-center">
    <div class="box_top">
      <Header />
    </div>
    <div class="refer-wrapper">
      <div
        class="refer-item"
        v-for="(item, index) in list"
        :key="index"
      >
        <div class="label">
          {{ item.label }}
        </div>
        <el-popover
          placement="bottom"
          width="200"
          trigger="click"
        >
          <img
            style="width: 200px"
            src="../../../../assets/imgs/pay.png"
          >
          <el-button
            type="primary"
            size="small"
            @click="openQrcode"
            slot="reference"
            class="refer-btn"
          >
            支付10元阅读
          </el-button>
        </el-popover>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header';
import Footer from '@/components/Footer.vue';

export default {
  name: 'ReferenceCenter',
  components: {
    Header, Footer,
  },
  data() {
    return {
      list: [
        { label: '配置菜单角标显示数据总数案例' },
        { label: 'JEPAAS如何配置平台第三方数据源案例' },
        { label: 'JEPAAS如何实现列表数据到期处理案例' },
        { label: '提高多个子功能集合加载表单效率案例' },
        { label: '配置独立的菜单模块案例' },
        { label: 'JEPAAS功能上如何实现一个数字提醒案例' },
        { label: '复写JEPAAS平台的增删改查方法案例' },
        { label: '在JEPAAS平台加载时就显示客户自己的logo案例' },
      ],
    };
  },
  mounted() {

  },
  methods: {
    openQrcode() {

    },
  },
};
</script>

<style lang='less' scoped>
.reference-center {
  text-align: initial;
  background:url('../../../../assets/imgs/referBg.png') no-repeat 0 80px;
  background-size: 100% auto;
  .box_top {
    width: 100%;
    height: 80px;
    background: linear-gradient(
      270deg,
      rgba(252, 103, 81, 1) 0%,
      rgba(246, 71, 77, 1) 100%
    );
  }
  .refer-wrapper {
    width: 1250px;
    margin: 30px auto;
    border-radius: 6px;
    box-shadow:0px -2px 19px 4px rgba(83,83,83,0.2);
    background-color: #fff;
    padding: 20px 0;
    .refer-item {
      display: flex;
      align-items: center;
      padding: 10px 40px;
      margin-bottom: 15px;
      .label {
        flex: 1;
        font-size: 16px;
      }
      .refer-btn {
        padding: 8px 50px;
      }
      &:hover {
        background-color: #f6f6f6;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
